import { Link } from "react-router-dom";
import "./index.css";
import Pages from ".";
import waldkalkung from "../pdfs/Waldkalkung FBG Breun 20170822.pdf";
import teilnahmeerklaerungWaldkalkung from "../pdfs/Teilnahmeerklaerung Waldkalkung 20170822.pdf";
import satzung from "../pdfs/satzung.pdf";
import PEFCUrkunde from "../pdfs/PEFC_Urkunde_20210923.pdf";
import aufnahmeAntrag from "../pdfs/2025/FBG_Breun_Aufnahmeantrag.pdf";
import antrag_Betriebshaftpflicht from "../pdfs/2025/FBG_Breun_Antrag_Haftpflicht.pdf";
import waldbesitz from "../pdfs/2025/FBG_Breun_Aufstellung_Waldbesitz_zur_Aufnahme.pdf";
import PEFC from "../pdfs/2025/pefc_d_selbstverpflichtungserklarung_einzelne_waldbesitzende_2020_v4_-_formular.pdf";

const Home = ({}) => {
  return (
    <div className="globalMargin">
      <h2 className="PageHeader header">Willkommen</h2>

      <div className="PageBody">
        <p>auf der Homepage der FBG-Breun</p>
        <p>
          Die Forstbetriebgemeinschaft Breun ist eine Forstbetriebsgemeinschaft
          nach dem Gesetz zur Erhaltung des Waldes und zur Förderung der
          Forstwirtschaft (Bundeswaldgesetz vom 2.05.1975 BGBL. IS. 1037 v.
          7.5.1975) und ein wirtschaftlicher Verein im Sinne von §22 BGB. Die
          Forstbetriebsgemeinschaft hat den Zweck, die Bewirtschaftung der
          angeschlossenen Waldgrundstücke zu verbessern. Die FBG übernimmt für
          ihre Mitglieder Aufgaben die jedem einzelnen Waldbesitzer
          wirtschaftlich nicht zumutbar erscheinen oder für sich alleine nicht
          lohnen. Interessen der Einzelnen werden so gestärkt.
          <br />
        </p>
        <div>
          <p>
            Von der FBG werden folgenden Aufgaben übernommen:
            <br /> Gemeinsamer Pflanzeneinkauf, Bereitstellung von Hölzern für
            Versteigerungen und Submissionen, Sammelverträge zur
            Waldbrandversicherung, Beantragung von Fördermitteln, Durchführung
            von Waldschutzkalkungen, Weiterbildung der Waldbesitzer durch
            Lehrgänge, Vermittlung von Waldarbeitern oder Unternehmen zur
            Durchführung forstlicher Maßnahmen.
            <br />
          </p>
          <p>
            Die FBG-Breun ist seit dem 14.10.2002 nach PEFC zertifiziert.
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={PEFCUrkunde}
            >
              (PEFC-Urkunde)
            </a>
          </p>
          <p>
            Weitere Informationen unter{" "}
            <Link className="globalLink" to={Pages.WALDBETREUUNG.path}>
              Waldbetreuung
            </Link>
            .
          </p>
        </div>
        <br />
        <h3>Mitglied werden?</h3>
        <div>
          Mitglied der FBG-Breun kann der werden, der Eigentümer oder
          Nutzungsberechtigter von Waldflächen oder von zur Aufforstung
          bestimmte Grundstücken ist, die in der Bemarkung Breun liegen. Es
          werden nur neue Mitglieder aufgenommen die auch der
          PEFC-Zertifizierung beitreten.
          <br />
          <p>
            <span className="Important">Hier herunterladen:</span>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={aufnahmeAntrag}
            >
              Beitrittserklärung (2025)
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={satzung}
            >
              Satzung
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={antrag_Betriebshaftpflicht}
            >
              Antrag Betriebshaftpflicht (2025)
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={waldbesitz}
            >
              Aufstellung Waldbesitz (2025)
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={PEFC}
            >
              PEFC-Antrag (2025)
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={waldbesitz}
            >
              Flächen u. Besitzänderung (2025)
            </a>
          </p>
          <br />
          <span className="Important">Wichtig!</span>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={teilnahmeerklaerungWaldkalkung}
            >
              Waldkalkung 2018 Teilnahmeerklärung
            </a>
          </p>
          <p>
            <a
              className="globalLink"
              rel="noopener noreferrer"
              target="_blank"
              href={waldkalkung}
            >
              Informationen zur Waldkalkung 2018
            </a>
          </p>
          <br />
          <br />
        </div>
      </div>
    </div>
  );
};

export default Home;
